import { useState, useEffect } from "react";
import imgStand from "../images/1.png";
import imgBack from "../images/2.png";
import imgSideOne from "../images/3.png";
import imgSideTwo from "../images/4.png";
import imgSnout from "../images/5.png";
import imgNeck from "../images/6.png";

const PIG_POSITIONS = {
  sideOne: [1, 0.3373, imgSideOne],
  sideTwo: [1, 0.2824, imgSideTwo],
  stand: [5, 0.075, imgStand],
  back: [5, 0.259, imgBack],
  snout: [10, 0.353, imgSnout],
  neck: [15, 0.0039, imgNeck],
};

const usePigGame = () => {
  const [rollPoints, setRollPoints] = useState(["", ""]);
  const [displayedMessage, setDisplayedMessage] = useState("");
  const [currentPlayerIndex, setCurrentPlayerIndex] = useState(0);
  const [roundPoints, setRoundPoints] = useState(0);
  const [currentPoints, setCurrentPoints] = useState(0);
  const [pigImage, setPigImage] = useState([imgStand, imgStand]);

  // Load Players and Points
  const [playerPoints, setPlayerPoints] = useState(
    JSON.parse(localStorage.getItem("LOCALSTORAGE_PlayerPoints")) || {
      "You ": 0,
    }
  );

  // UseEffect that keeps an eye on player points and saves them locally
  useEffect(() => {
    localStorage.setItem(
      "LOCALSTORAGE_PlayerPoints",
      JSON.stringify(playerPoints)
    );
  }, [playerPoints]);

  function getRandomPosition() {
    const positions = Object.keys(PIG_POSITIONS);
    const chances = [];
    positions.forEach((position) => {
      for (let i = 1; i < PIG_POSITIONS[position][1] * 100; i++) {
        chances.push(position);
      }
    });
    return chances[Math.floor(Math.random() * chances.length)];
  }

  function switchPlayer() {
    let players = Object.keys(playerPoints);
    setCurrentPlayerIndex((prevIndex) =>
      prevIndex === players.length - 1 ? 0 : (prevIndex += 1)
    );
    setCurrentPoints(0);
  }

  let newName = "New Player";

  function addNewPlayer() {
    let newIndex = 1;

    while (Object.keys(playerPoints).includes(newName)) {
      newName = `New Player ${newIndex}`;
      newIndex += 1;
    }

    setPlayerPoints((prevState) => ({
      ...prevState,
      [newName]: 0,
    }));
  }

  function deletePlayer(playerNameInput) {
    setPlayerPoints((prevState) => {
      const newState = { ...prevState };
      delete newState[playerNameInput];
      return newState;
    });
  }

  function updatePlayerName(oldName, newName) {
    setPlayerPoints((prevState) => {
      const entries = Object.entries(prevState); // Convert the object to an array of entries

      const index = entries.findIndex(([name]) => name === oldName); // Find the index of the entry with the old name

      // If the old name exists, update it
      if (index !== -1) {
        entries[index][0] = newName;
      }

      const newState = Object.fromEntries(entries); // Convert the entries array back to an object

      return newState;
    });
  }

  function commitPoints() {
    const players = Object.keys(playerPoints);
    const currentPlayer = players[currentPlayerIndex];
    setPlayerPoints((prevPoints) => ({
      ...prevPoints,
      [currentPlayer]: prevPoints[currentPlayer] + currentPoints,
    }));
    switchPlayer();
  }

  var isCurrentlyScramblingForDramaticEffect = false;

  function setRollPointsAndPigImage() {
    const roll1 = getRandomPosition();
    const roll2 = getRandomPosition();

    setRollPoints([
      `${roll1}: ${PIG_POSITIONS[roll1][0]}`,
      `${roll2}: ${PIG_POSITIONS[roll2][0]}`,
    ]);
    setPigImage([PIG_POSITIONS[roll1][2], PIG_POSITIONS[roll2][2]]);

    return [roll1, roll2];
  }

  function scramblePigsForDramaticEffect() {
    let speed = 1;
    let pigToggleSwitch = false;
    let pigScrambledTimes = 0;

    isCurrentlyScramblingForDramaticEffect = true;
    return new Promise((resolve) => {
      function scramble() {
        speed *= 1.05;
        pigToggleSwitch = !pigToggleSwitch;
        pigScrambledTimes++;

        setRollPointsAndPigImage();

        if (pigScrambledTimes > 30) {
          isCurrentlyScramblingForDramaticEffect = false;
          resolve();
        } else {
          setTimeout(scramble, 50 * speed);
        }
      }

      scramble();
    });
  }

  async function rollPig() {
    if (Object.keys(playerPoints).length < 2) {
      setDisplayedMessage("You need at least 2 players to play");
      return;
    }

    await scramblePigsForDramaticEffect();

    let [roll1, roll2] = setRollPointsAndPigImage();

    const isDifferentSides =
      (roll1 === "sideOne" && roll2 === "sideTwo") ||
      (roll1 === "sideTwo" && roll2 === "sideOne");
    const isSameRoll = roll1 === roll2;

    let points = 0;
    if (isDifferentSides) {
      setDisplayedMessage("You lost");
      switchPlayer();
    } else {
      if (isSameRoll && (roll1 === "sideOne" || roll1 === "sideTwo")) {
        points = 1;
      } else if (isSameRoll) {
        points = (PIG_POSITIONS[roll1][0] + PIG_POSITIONS[roll2][0]) * 2;
      } else if (roll1 === "sideOne" || roll1 === "sideTwo") {
        points = PIG_POSITIONS[roll2][0];
      } else if (roll2 === "sideOne" || roll2 === "sideTwo") {
        points = PIG_POSITIONS[roll1][0];
      } else {
        points = PIG_POSITIONS[roll1][0] + PIG_POSITIONS[roll2][0];
      }
      setRoundPoints(points);
      setCurrentPoints((prevPoints) => prevPoints + points);
      setDisplayedMessage(currentPoints + points);
    }
  }

  return {
    rollPoints,
    playerPoints,
    displayedMessage,
    currentPlayerIndex,
    roundPoints,
    currentPoints,
    pigImage,
    addNewPlayer,
    deletePlayer,
    updatePlayerName,
    commitPoints,
    rollPig,
  };
};

export default usePigGame;
