const PigContainer = ({ rollPoints, pigImage }) => {
  return (
    <div style={{ display: "flex", gap: "20px" }}>
      <div className="pigContainer">
        {!rollPoints[0] ? <p>Pig 1 ready</p> : <p>{rollPoints[0]}</p>}
        <img src={pigImage[0]} alt="pig" />
      </div>
      <div className="pigContainer">
        {!rollPoints[1] ? <p>Pig 2 ready</p> : <p>{rollPoints[1]}</p>}
        <img src={pigImage[1]} alt="pig" />
      </div>
    </div>
  );
};

export default PigContainer;
