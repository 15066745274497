import "./App.css";
import usePigGame from "./Hooks/usePigGame";
import PlayerList from "./Components/PlayerList";
import PigContainer from "./Components/PigContainer";
import Header from "./Components/Header";

function App() {
  const {
    rollPoints,
    playerPoints,
    newPlayerName,
    displayedMessage,
    currentPlayerIndex,
    roundPoints,
    currentPoints,
    pigImage,
    setNewPlayerName,
    addNewPlayer,
    deletePlayer,
    updatePlayerName,
    commitPoints,
    rollPig,
  } = usePigGame();

  return (
    <div className="App">
      <Header
        rollPoints={rollPoints}
        roundPoints={roundPoints}
        displayedMessage={displayedMessage}
        playerPoints={playerPoints}
        currentPlayerIndex={currentPlayerIndex}
      />
      <PigContainer rollPoints={rollPoints} pigImage={pigImage} />
      <button onClick={rollPig} id="rollPigButton">
        Roll
      </button>
      <button onClick={commitPoints} id="commitPointsButton">
        Commit points
      </button>
      <PlayerList
        playerPoints={playerPoints}
        addNewPlayer={addNewPlayer}
        deletePlayer={deletePlayer}
        updatePlayerName={updatePlayerName}
        newPlayerName={newPlayerName}
        setNewPlayerName={setNewPlayerName}
        currentPlayerIndex={currentPlayerIndex}
      />
    </div>
  );
}

export default App;
