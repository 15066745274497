import { useState } from "react";

const PlayerList = ({
  playerPoints,
  addNewPlayer,
  deletePlayer,
  newPlayerName,
  setNewPlayerName,
  updatePlayerName,
  currentPlayerIndex,
}) => {
  const [editingPlayer, setEditingPlayer] = useState(null);
  const [editingName, setEditingName] = useState("");

  function startEditing(playerName) {
    setEditingPlayer(playerName);
    setEditingName(playerName);
  }

  function stopEditing(playerName) {
    updatePlayerName(playerName, editingName);
    setEditingPlayer(null);
    setEditingName("");
  }

  function returnNameOfCurrentPlayer() {
    return Object.keys(playerPoints)[currentPlayerIndex];
  }

  return (
    <div className="playerContainer">
      {Object.entries(playerPoints).map(([playerName, points]) => (
        <div
          className={`${
            returnNameOfCurrentPlayer() === playerName
              ? "ID_currentPlayer playerElement"
              : "playerElement"
          }`}
          key={playerName}
          id={`PLAYER_${playerName}`}
        >
          <div className="playerMainInside">
            <p>{points}</p>
            {editingPlayer === playerName ? (
              <input
                autoFocus
                onFocus={(e) => e.target.select()}
                value={editingName}
                onChange={(e) => setEditingName(e.target.value)}
                onBlur={() => stopEditing(playerName)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") stopEditing(playerName);
                }}
              />
            ) : (
              <p onClick={() => startEditing(playerName)}>{playerName}</p>
            )}
          </div>
          <button
            onClick={() => deletePlayer(playerName)}
            id="deletePlayerButton"
          >
            x
          </button>
        </div>
      ))}
      <div>
        <button
          onClick={() => addNewPlayer(newPlayerName)}
          id="addNewPlayerButton"
        >
          Add new player
        </button>
      </div>
    </div>
  );
};

export default PlayerList;
