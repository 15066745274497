const Header = ({
  rollPoints,
  roundPoints,
  displayedMessage,
  playerPoints,
  currentPlayerIndex,
}) => {
  return (
    <header className="App-header">
      <h2>Kaste gris! hæhæ</h2>
      {rollPoints[0] === 0 ? (
        <p> Press "Roll" to play</p>
      ) : (
        <p className="ThrowPointsText">+{roundPoints}</p>
      )}
      {Number.isInteger(displayedMessage) ? (
        <p className="RoundPointsText">Points this round: {displayedMessage}</p>
      ) : (
        <p className="RoundPointsText">{displayedMessage}</p>
      )}
      <p>Current player: {Object.keys(playerPoints)[currentPlayerIndex]}</p>
    </header>
  );
};

export default Header;
